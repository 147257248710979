import {Button} from "@fluentui/react-components";
import { ArrowCounterclockwise24Regular } from '@fluentui/react-icons';
import RenderTTS from "./RenderTTS";

function RenderSolution(props) {
    return <div className={"solututionContainer"}>
        Sieht dein Blatt so aus?
        <img alt={""} src={"pictures/" + props.solution.solutionImage} className={"image"}/>
        Dann ist es {(props.solution.solutionText.indexOf("ahorn") !== -1?"ein":"eine") + " " + props.solution.solutionText}
        <RenderTTS text={"Sieht dein Blatt so aus? Dann ist es " + ((props.solution.solutionText.indexOf("ahorn") !== -1)?"ein":"eine") + " " + props.solution.solutionText} />
        <Button onClick={() => props.restart()}><ArrowCounterclockwise24Regular />&nbsp;Neustarten</Button>
    </div>;
}

export default RenderSolution;
