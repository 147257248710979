import { useEffect, useState } from "react";
import RenderNode from "./RenderNode";
import "./style.css";


function App(props) {
    const [currentNode, setCurrentNode] = useState(undefined);
    const [config, setConfig] = useState(undefined);
    useEffect(() => {
        readConfig().then(r => {
            setCurrentNode(r);
            setConfig(r);
        })
    }, []);

    const changeCurrentNode = (newNode) => {
        setCurrentNode(newNode);
    };

    const restart = () => {
        setCurrentNode(config);
    }

    const goBack = () => {
        console.log("test");
    }

    return (
        <div className={"rootContainer"}>
            {currentNode ? <RenderNode currentNode={currentNode} back={goBack} changeCurrentNode={changeCurrentNode}
                restart={restart}></RenderNode> : <div>Config nicht gefunden</div>}
        </div>
    );
}

async function readConfig() {
    return await (await fetch("config.json")).json();
}


export default App;
