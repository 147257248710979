import {Button} from "@fluentui/react-components";
import React from "react";
import {Comment20Regular} from '@fluentui/react-icons';


const synth = window.speechSynthesis;
const voice = synth.getVoices().filter(r => r.lang === "de-DE")[0];
const voiceReed = synth.getVoices().filter(r => r.lang === "de-DE" && r.name.indexOf("Reed") !== -1);
const voiceToUse = voiceReed.length > 0 ? voiceReed[0]:voice;

function RenderTTS(props) {

    const playSound = () => {
        const utterThis = new SpeechSynthesisUtterance(props.text);
        utterThis.voice = voiceToUse;
        synth.speak(utterThis);
    }

    if(!props.text) {
        return <></>;
    }

    return <Button onClick={() => playSound()}>
        <Comment20Regular/>&nbsp;&nbsp;Vorlesen
    </Button>;
}

export default RenderTTS;
