import { useEffect, useState } from "react";
import RenderNode from "./RenderNode";
import "./style.css";


function Tabelle(props) {
    const [currentNode, setCurrentNode] = useState(undefined);
    const [config, setConfig] = useState(undefined);
    useEffect(() => {
        readConfig().then(r => {
            const solutions = convertConfigToTable(r);
            const transformedConfig = {
                type: "decision",
                decisionText: "Welches Blatt entspricht deinem?",
                choices: solutions
            };

            setCurrentNode(transformedConfig);
            setConfig(transformedConfig);
        })
    }, []);

    const changeCurrentNode = (newNode) => {
        setCurrentNode(newNode);
    };

    const restart = () => {
        setCurrentNode(config);
    }

    const goBack = () => {
        console.log("test");
    }

    return (
        <div className={"rootContainer"}>
            {currentNode ? <RenderNode currentNode={currentNode} back={goBack} changeCurrentNode={changeCurrentNode}
                restart={restart}></RenderNode> : <div>Config nicht gefunden</div>}
        </div>
    );
}

function convertConfigToTable(config) {
    const solutions = findSolutionInNode(config);
    solutions.forEach(r => {
        r.label = "";
        r.image = r.solutionImage;
    });
    return solutions;
}

function findSolutionInNode(node) {
    if(node.type === "solution") {
        return [node];
    }

    return node.choices.map(r => findSolutionInNode(r)).flat();
}

async function readConfig() {
    return await (await fetch("config.json")).json();
}


export default Tabelle;
