import RenderDecision from "./RenderDecision";
import RenderSolution from "./RenderSolution";
import "./style.css";

function RenderNode(props) {
    var content = <div>Type unbekannt</div>;
    if (props.currentNode.type === "decision") {
        content = <RenderDecision decision={props.currentNode} goBack={props.back} setChoice={props.changeCurrentNode}></RenderDecision>
    } else if (props.currentNode.type === "solution") {
        content = <RenderSolution solution={props.currentNode} restart={props.restart}></RenderSolution>
    }

    return <div className={"nodeContainer"}>
        {content}
    </div>;
}

export default RenderNode;
