import "./style.css";
import RenderTTS from "./RenderTTS";
/*import {Button} from "@fluentui/react-components";
import { ArrowLeft20Filled } from '@fluentui/react-icons';*/

function RenderDecision(props) {
    return <div className={"decisionContainer"}>
        <div className={"innerWrapper"}>
            <div className={"questionContainer"}>
                {/*
            <Button className={"backButton"} onClick={() => props.goBack()}><ArrowLeft20Filled/></Button>
        */}
                <div className={"questionTextContainer"}>
                    <div className={"choiceLabel"}>{props.decision.decisionText}</div>
                    <RenderTTS text={props.decision.decisionText} />
                </div>
            </div>
            <div className={"choicesContainer"}>
                {props.decision.choices.map(c => {
                    return <div key={c.image} className={"choice"}>
                        <div className={"choiceContainer"} onClick={() => props.setChoice(c)}>
                            <img src={"/pictures/" + c.image} alt={""} className={"image"} />
                            <div className={"spacer"}></div>
                            <div className={"choiceLabel"}>{c.label}</div>
                        </div>
                        <RenderTTS key={c.label + "tts"} text={c.label} />
                    </div>;
                })}
            </div>
        </div>
    </div>;
}

export default RenderDecision;
